<template>
	<div class="review-card">
		<div class="review-card__top">
			<div class="review-card__image">
				<tk-image
					width="89"
					height="89"
					:src="content.preview"
					:alt="content.title"
				/>
			</div>
			<div class="review-card__user">
				<span class="review-card__name">{{ content.title }}</span>
				<!-- <span class="review-card__age">{{ item.age }}</span> -->
				<!-- <span class="review-card__period">{{ getPeriod(item.date_from, item.date_to) }}</span> -->
			</div>
		</div>
		<div class="review-card__text">
			{{ content.description }}
		</div>
		<router-link :to="`/article/${content.alias}`" class="review-card__link">
			Читать полностью
		</router-link>
	</div>
</template>

<script>
export default {
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	}
}
</script>

<style lang="sass">
.review-card
  display: flex
  flex-direction: column
  margin-right: 5px
  padding: 30px 55px
  width: 100%
  height: 100%
  max-width: 590px
  border-radius: 10px
  background: #F7F7F7

  @media screen and (max-width: 600px)
    padding: 20px 22px

  &__top
    display: grid
    grid-template-columns: 90px auto
    grid-column-gap: 20px
    margin-bottom: 25px

  &__image
    width: 89px
    height: 89px
    border-radius: 100%
    overflow: hidden

  &__user
    text-align: left

  &__name
    margin-bottom: 5px
    font-weight: 600
    font-size: 22px
    line-height: 26px
    color: #2A3043

  // & .age
  //   font-weight: 600
  //   font-size: 18px
  //   line-height: 21px
  //   color: #2A3043
  //   margin-bottom: 15px

  // & .period
  //   font-size: 14px
  //   line-height: 16px
  //   color: #BDBDBD

  &__text
    margin-bottom: 40px
    text-align: left
    font-size: 14px
    line-height: 22px
    color: #8B8B8B

  &__link
    display: flex
    justify-content: center
    align-items: center
    margin-top: auto
    width: 183px
    height: 54px
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 16px
    color: #FFFFFF
    text-decoration: none
    background: #2A3043
    border-radius: 10px

    @media screen and (max-width: 600px)
      width: 100%
</style>
